/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Product, WaterMetricsSupply } from '@/store/models/Product';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import { SetVisibilityOfTableColumns } from '../TableColumnVisibility';
import { AllowedField, WaterAllowedField } from '../types/AllowedFields';
import {
  GetVisibleCustomColumnsFormatters,
  tableColumnFormatters
} from './TableFields';
import {
  formatCurrentUsageDisplay,
  formatUsageTodayDisplay,
  formatUsageYesterdayDisplay
} from '../formatters/DisplayValueFormatters';

export const waterSpecificTableColumnsFormatters: {
  [key in WaterAllowedField]: TableColumnFormatters;
} = {
  [WaterAllowedField.CUSTOM_ID]: {
    name: WaterAllowedField.CUSTOM_ID,
    label: () => 'Id',
    visible: true,
    width: 100,
    filterable: false,
    searchable: true,
    sortable: true,
    searchKey: (item: Product) => {
      return item.partnerDefinedId;
    },
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by partner defined id and entities by entity id
      return sortType == 'child' ? item.entityId : item.partnerDefinedId;
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return item.partnerDefinedId ? item.partnerDefinedId : '';
    },
    childFormattedValue: (item: Product, entity: any) => {
      return ''; //blank
    },
    textStyling: 'textOverflowEllipsis'
  },
  [WaterAllowedField.CURRENT_USAGE]: {
    name: WaterAllowedField.CURRENT_USAGE,
    label: () => 'Current Usage',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min current usage of entities
      //Sort entities by current usage value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterMetricsSupply).currentUsage;
      } else {
        return (item.entities[0].metrics as WaterMetricsSupply).currentUsage;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatCurrentUsageDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatCurrentUsageDisplay(entity);
    }
  },
  [WaterAllowedField.USAGE_TODAY]: {
    name: WaterAllowedField.USAGE_TODAY,
    label: () => 'Usage Today',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min usage of entities
      //Sort entities by usage value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterMetricsSupply).usageToday;
      } else {
        return (item.entities[0].metrics as WaterMetricsSupply).usageToday;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatUsageTodayDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatUsageTodayDisplay(entity);
    }
  },
  [WaterAllowedField.USAGE_YESTERDAY]: {
    name: WaterAllowedField.USAGE_YESTERDAY,
    label: () => 'Usage Yesterday',
    visible: true,
    width: 140,
    filterable: true,
    searchable: false,
    sortable: true,
    sortKey: (item: Product, sortType: 'child' | 'parent') => {
      //Sort product level by the max/min usage of entities
      //Sort entities by usage value
      if (sortType == 'child' || !item.entities) {
        return (item.metrics as WaterMetricsSupply).usageYesterday;
      } else {
        return (item.entities[0].metrics as WaterMetricsSupply).usageYesterday;
      }
    },
    parentFormattedValue: (item: Product, entity: any) => {
      return formatUsageYesterdayDisplay(entity);
    },
    childFormattedValue: (item: Product, entity: any) => {
      return formatUsageYesterdayDisplay(entity);
    }
  }
};

export const waterTableColumnsFormatters = {
  ...tableColumnFormatters,
  ...waterSpecificTableColumnsFormatters
};

export function GetWaterTableColumnFormatters(
  fields: (WaterAllowedField | AllowedField)[]
) {
  const baseColumnsWithFormatters = fields.map(column => {
    return waterTableColumnsFormatters[column];
  });
  return baseColumnsWithFormatters;
}

export async function GetVisibleWaterTableColumnFormatters(
  fields: (WaterAllowedField | AllowedField)[],
  addCustomFields: boolean
) {
  const baseColumnsWithFormatters = SetVisibilityOfTableColumns(
    fields.map(column => {
      return waterTableColumnsFormatters[column];
    })
  ).filter(column => {
    return column.visible;
  });

  if (addCustomFields) {
    const customColumnsWithFormatters = await GetVisibleCustomColumnsFormatters();
    return [...baseColumnsWithFormatters, ...customColumnsWithFormatters];
  } else {
    return baseColumnsWithFormatters;
  }
}

export function GetDefaultWaterColumns() {
  return Object.fromEntries(
    Object.entries(waterTableColumnsFormatters).filter(column => {
      return column[0].includes('Default');
    })
  );
}
