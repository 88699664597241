import CustomField from '@/store/models/CustomField';
import { Filters } from '@/store/models/Filters';
import { Product } from '@/store/models/Product';
import { TableColumnFormatters } from '@/store/models/TableColumn';
import { isApp } from '../AppName';
import { Constants } from '../Constants';
import { reduceMaxValue } from '../formatters/EnititesFormatter';
import { getAppRoute } from '../GenericUtils';
import { getObjectItem } from '../LocalStorageUtils';
import { productType } from '../types/ProductType';
import { isUpperBoundDefault } from '../FiltersUtils';

export class FiltersClass<T extends Filters> {
  public maxVolume = 0;
  public maxCapacity = 0;
  public filters: T;
  public app: productType;

  constructor(app: productType, filters: T) {
    this.app = app;
    this.filters = filters;
    this.loadSavedFilters();
  }

  public get anyFiltersSet(): boolean {
    return (
      (this.filters.customEntityFilters ?? []).length > 0 ||
      (this.filters.customSiteFilters ?? []).length > 0
    );
  }

  public checkRange(range: Array<any>, value: number): boolean {
    return range[1] != 0 && range[1] != value;
  }

  public checkStateFilter(stateFilter: string[], product: Product) {
    let matchesStateFilter = true;
    if (!stateFilter.includes('actioned') && product.entityComments) {
      matchesStateFilter = false;
    }
    if (!stateFilter.includes('unactioned') && !product.entityComments) {
      matchesStateFilter = false;
    }
    return matchesStateFilter;
  }

  public filterAlerts(
    products: Product[],
    stateFilter = ['actioned', 'unactioned'],
    showAll = true
  ): Product[] {
    return products?.filter(product => {
      let hasAlert = false;
      if (!showAll) {
        hasAlert = (product?.metrics?.alert ?? 0) == 2;
      } else {
        hasAlert = (product?.metrics?.alert ?? 0) > 0;
      }
      return hasAlert && this.checkStateFilter(stateFilter, product);
    });
  }

  public filterProducts(products: Product[]) {
    let filtered = [...products];
    if (this.filters.customSiteFilters) {
      (this.filters.customSiteFilters as {
        field: CustomField;
        selection: string[];
      }[]).forEach(filter => {
        filtered = filtered?.filter(product => {
          if (product.customFieldData) {
            return filter.selection.includes(
              product.customFieldData[filter.field.name]
            );
          }
          return false;
        });
      });
    }
    if (this.filters.customEntityFilters) {
      (this.filters.customEntityFilters as {
        field: CustomField;
        selection: string[];
      }[]).forEach(filter => {
        filtered = filtered?.filter(product => {
          if (product.entityCustomFieldData) {
            return filter.selection.includes(
              product.entityCustomFieldData[filter.field.name]
            );
          }
          return false;
        });
      });
    }
    return filtered;
  }

  public filterRoute(products: Product[]) {
    const route = getAppRoute();
    return products.filter(product => {
      const entityId = product.entityId ?? '';
      return (
        route.routePlanProducts.includes(product.productId) &&
        (route.routePlanEntities.includes(entityId) ||
          (isApp(Constants.PRODUCT_TYPE_MILK) &&
            route.routePlanEntities.includes(`all${product.productId}`)))
      );
    });
  }

  public filterSearch(
    products: Product[],
    searchTerm: string,
    tableColumns: TableColumnFormatters[]
  ): Product[] {
    const searchableColumns = tableColumns.filter(
      (column: TableColumnFormatters) => column.searchable && column.searchKey
    );
    return products?.filter(product => {
      //search matches at least one of searchable properties
      let searchResult = false;
      if (searchTerm != '') {
        searchableColumns.forEach((column: TableColumnFormatters) => {
          if (
            column.searchKey &&
            column.searchKey(product) &&
            column
              .searchKey(product)
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase()) != -1
          ) {
            searchResult = true;
          }
        });
        return searchResult;
      } else {
        return true;
      }
    });
  }

  public get filtersSet(): boolean {
    return (
      (this.anyFiltersSet || this.lowerBoundSet || this.upperBoundSet) &&
      this.tankParametersSet
    );
  }

  public loadSavedFilters() {
    const saved = getObjectItem(`${this.app}Filters`);
    if (saved && saved.filters) {
      Object.keys(this.filters).forEach(filter => {
        if (saved.filters[filter]) {
          (this.filters as any)[filter] = saved.filters[filter];
        }
      });
    }
  }

  public get lowerBoundSet(): boolean {
    if (this.app == 'water') {
      return false;
    } else {
      return [this.filters.volumeRange[0], this.filters.capacityRange[0]].some(
        value => value != 0
      );
    }
  }

  public setMaxCapacity(maxCap: number) {
    if (
      this.filters.capacityRange[1] == this.maxCapacity ||
      this.filters.capacityRange[1] == null
    ) {
      this.filters.capacityRange[1] = maxCap;
    }

    this.maxCapacity = maxCap;
  }

  public setMaxRanges(products: Product[]) {
    this.setMaxVolume(
      Math.ceil(
        reduceMaxValue(
          products,
          isApp(Constants.PRODUCT_TYPE_MILK) ? 'vatVolume' : 'volume'
        ) / 500
      ) * 500
    );
    this.setMaxCapacity(
      Math.ceil(
        reduceMaxValue(
          products,
          isApp(Constants.PRODUCT_TYPE_MILK) ? 'capacity' : 'nominalVolume'
        ) / 500
      ) * 500
    );
  }

  public setMaxVolume(maxVol: number) {
    if (
      this.filters.volumeRange[1] == this.maxVolume ||
      this.filters.volumeRange[1] == null
    ) {
      this.filters.volumeRange[1] = maxVol;
    }

    this.maxVolume = maxVol;
  }

  public get tankParametersSet(): boolean {
    return this.maxVolume != 0 && this.maxCapacity != 0;
  }

  public get upperBoundSet(): boolean {
    if (this.app == 'water') {
      return false;
    } else {
      return [
        isUpperBoundDefault(this.filters.volumeRange[1], this.maxVolume),
        isUpperBoundDefault(this.filters.capacityRange[1], this.maxCapacity)
      ].includes(false);
    }
  }
}
