import { Constants } from '@/utils/Constants';
import { ComplianceSettings } from './MilkScore';
import { AmountMetric } from '@/utils/types/AmountMetric';
export interface PreferredDefaults {
  preferredLabel: string;
  preferredLevelMetric?: AmountMetric;
}

export interface FuelCustomSettingsData extends PreferredDefaults {
  criticalLevelThreshold?: number;
  lowLevelThreshold?: number;
  usageDayPattern?: 5 | 6 | 7;
}

export interface MilkCustomSettingsData extends PreferredDefaults {
  complianceMethods: ComplianceSettings[];
}

export interface FeedCustomSettingsData extends PreferredDefaults {
  criticalLevelThreshold?: number;
  lowLevelThreshold?: number;
}

export interface CustomSettings {
  [Constants.PRODUCT_TYPE_FUEL]?: FuelCustomSettings;
  [Constants.PRODUCT_TYPE_MILK]?: MilkCustomSettings;
  [Constants.PRODUCT_TYPE_FEED]?: FeedCustomSettings;
  [Constants.PRODUCT_TYPE_WATER]?: {};
  [Constants.PRODUCT_TYPE_GENERIC]?: {};
}

export interface FuelCustomSettings {
  [Constants.API_ENTITY_TYPE_FUEL]?: FuelCustomSettingsData;
}

export interface FeedCustomSettings {
  feed_tanks?: FeedCustomSettingsData;
}

export interface MilkCustomSettings {
  [Constants.API_ENTITY_TYPE_MILK]?: MilkCustomSettingsData;
}

export const defaultCustomSettings: CustomSettings = {
  [Constants.PRODUCT_TYPE_FUEL]: {
    [Constants.API_ENTITY_TYPE_FUEL]: {
      criticalLevelThreshold: 10,
      lowLevelThreshold: 35,
      usageDayPattern: 7,
      preferredLabel: 'tank'
    }
  },
  [Constants.PRODUCT_TYPE_FEED]: {
    [Constants.API_ENTITY_TYPE_FEED]: {
      criticalLevelThreshold: 10,
      lowLevelThreshold: 35,
      preferredLabel: 'tank'
    }
  },
  [Constants.PRODUCT_TYPE_MILK]: {
    [Constants.API_ENTITY_TYPE_MILK]: {
      complianceMethods: [
        {
          complianceMethod: 'mpi',
          complianceMethodThreshold: 1,
          maxMilkAgeSecs: 259200,
          default: true
        }
      ],
      preferredLabel: 'vat'
    }
  }
};
