import HttpClient from '@/services/api/ApiBase';
import { ComplianceSettings } from '@/store/models/MilkScore';
import User from '@/store/modules/User';
import { getAppName } from '@/utils/AppName';
import { Constants } from '@/utils/Constants';
import { getMethod } from '@/utils/MilkScoreUtils';
import { DemoTimeseries, DemoTimeseriesBlank } from './models/DemoTimeseries';
import FeedTankTimeseries from './models/FeedTankTimeseries';
import { MilkProductTimeseriesReading } from './models/MilkProductTimeseries';
import TankTimeseries from './models/TankTimeseries';
import { VatMilkScoreTimeseries, VatTimeseries } from './models/VatTimeseries';
import { MeterTimeseriesReading } from './models/MeterTimeseries';

// Base Graph Api Calls
export const FetchVatTimeseries = async (
  entityId: string,
  startTime: string,
  finishTime: string,
  series: string[],
  source: string
): Promise<VatTimeseries> => {
  try {
    const graphData = await HttpClient.get(`graphs/vat/${entityId}`, {
      params: {
        startTs: startTime,
        endTs: finishTime,
        series: series.toString(),
        productType: getAppName(false),
        source: source
      }
    });
    return graphData.data.data;
  } catch (error) {
    console.error(error);
    return { volume: [], volumeEvents: [], vatTempEvents: [] };
  }
};

export const FetchMilkScoreHistoryTimeseries = async (
  entityID: string,
  customSettings: ComplianceSettings,
  startTime: string,
  finishTime: string
): Promise<VatMilkScoreTimeseries> => {
  try {
    const data = await HttpClient.get(
      `/vats/${entityID}/milkScoreHistory${
        getMethod(customSettings) == Constants.COMPLIANCE_METHOD_FONTERRA
          ? ''
          : '/' + getMethod(customSettings)
      }`,
      {
        params: {
          startTs: startTime,
          endTs: finishTime,
          productType: getAppName(false),
          source: 'Milk Score Graph'
        }
      }
    );
    return data.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const FetchProductsTimeseries = async (
  supplierNumber: string,
  supplier: string | undefined,
  nDays: number,
  offset: number,
  series: 'milking' | 'pickup',
  source: string
): Promise<MilkProductTimeseriesReading[]> => {
  let graphData;

  const supplierCode =
    supplier ?? User._token?.orgs[User._orgIndex].supplierCode;
  const params = {
    series: series,
    nDays: nDays,
    offset: offset,
    productType: getAppName(false),
    source: source
  };
  try {
    if (supplierCode == 'F' || supplierCode == 'F_') {
      graphData = await HttpClient.get(`/products/${supplierNumber}/graph`, {
        params: params
      });
    } else {
      graphData = await HttpClient.get(
        `/products/${supplierCode}_${supplierNumber}/graph`,
        {
          params: params
        }
      );
    }
    return graphData.data.data[series];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const FetchTankTimeseries = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  source: string
): Promise<TankTimeseries> => {
  let graphData;
  try {
    graphData = await HttpClient.get(`/farmer/fuel/tank/${entityID}/graph`, {
      params: {
        startTime: startTime,
        finishTime: finishTime,
        productType: getAppName(false),
        source: source
      }
    });
    return graphData.data;
  } catch (error) {
    console.error(error);
    return { daily: [], refill: [], withdrawal: [] };
  }
};

export const FetchFeedTankTimeseries = async (
  entityID: string,
  nDays: number,
  offset: number,
  series: string,
  source: string
): Promise<FeedTankTimeseries> => {
  let graphData;
  try {
    graphData = await HttpClient.get(`/feed/tanks/${entityID}/graphData`, {
      params: {
        series: series,
        nDays: nDays,
        offset: offset,
        productType: getAppName(false),
        source: source
      }
    });
    return graphData.data.data;
  } catch (error) {
    console.error(error);
    return { balance: [], level: [] };
  }
};

export const FetchWaterMeterTimeseries = async (
  entityID: string,
  startTime: string,
  finishTime: string,
  source: string
): Promise<MeterTimeseriesReading[]> => {
  let graphData;
  try {
    graphData = await HttpClient.get(
      `/farmer/water/meter/${entityID}/graph/volumes`,
      {
        params: {
          startTime: startTime,
          finishTime: finishTime,
          productType: getAppName(false),
          source: source,
          groupByUnit: 'day',
          groupByLength: 1
        }
      }
    );
    return graphData.data;
  } catch (error) {
    console.error('Error loading water meter graph volumes:', error);
    return [];
  }
};

export const FetchDemoTimeseries = async (
  entityId: string,
  startTime: string,
  finishTime: string,
  source: string
): Promise<DemoTimeseries> => {
  try {
    const graphData = await HttpClient.get(
      `https://7u18fm1n5e.execute-api.ap-southeast-2.amazonaws.com/default/farmer/${getAppName(
        false
      )}/vat/${entityId}/graph`,
      {
        params: { startTime: startTime, finishTime: finishTime, source: source }
      }
    );
    return graphData.data;
  } catch (error) {
    console.error(error);
    return DemoTimeseriesBlank;
  }
};
