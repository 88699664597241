
import { Component, Vue } from 'vue-property-decorator';
import AppHeader from '@/layout/AppHeader.vue';
import User from '@/store/modules/User';
import { initialiseMixpanel } from '@/services/Mixpanel';
import Chart from 'chart.js';

@Component({
  components: {
    AppHeader
  },
  computed: {
    isAuthenticated: () => User.isAuthenticated
  }
})
export default class App extends Vue {
  mounted() {
    initialiseMixpanel();
    Chart.defaults.global.defaultFontFamily = 'Sofia Pro';
  }
}
