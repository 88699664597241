import {
  getDaysFromUnix,
  hoursOld,
  relativeDay,
  timeAgo,
  timeOfDay
} from './DateUtils';

import ProductAPI from '@/services/api/models/ProductAPI';
import Comment from '@/store/models/Comment';
import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';
import CustomStore from '@/store/modules/CustomStore';
import dayjs from 'dayjs';
import LevelHistory from '../store/models/LevelHistory';
import { toDecamelize, toTitleCase } from './FormattingUtils';
import { rating } from '@/store/models/MilkScore';
import { Constants } from './Constants';
import { getUnit } from './AppName';
// A temporary helper function, for the temporary filters.

export const formatToHourAndMinutes = (time: string): string => {
  return parseInt(time.split(':')[0], 10) > 12
    ? Math.abs(12 - parseInt(time.split(':')[0], 10)) +
        ':' +
        time.split(':')[1] +
        'pm'
    : parseInt(time.split(':')[0], 10) + ':' + time.split(':')[1] + 'am';
};

export const formatMilkEnteredVat = (value: string) => {
  return value ? relativeDay(dayjs(value).unix()) : '';
};

export const formatTimeToHHmm = (value: string) => {
  return value ? dayjs(value).format('HH:mm') : '';
};

export const formatAgeMilk = (value: string) => {
  return value ? hoursOld(value) : '';
};

export const removeLongSupplierNumber = (value: string) => {
  return value.slice(0, 8);
};

export const formatNextMilking = (value: string) => {
  return timeOfDay(value);
};

export const formatMilkScoreColour = (
  value: rating,
  hasAreaOutOfSpec: boolean
): string => {
  const colours = {
    0: 'green',
    1: 'yellow',
    2: 'orange',
    3: 'red',
    4: 'red'
  };
  if (value == null || (value == 0 && !hasAreaOutOfSpec)) {
    return 'none';
  } else {
    return colours[value];
  }
};

export const formatMilkScoreDigit = (
  value: rating,
  hasAreaOutOfSpec: boolean
): rating => {
  //null = no score at all, 0 = green, 1 = yellow, 2 = orange, 3 || 4 = red
  if (value == null || (value == 0 && !hasAreaOutOfSpec)) {
    return null;
  } else {
    return value;
  }
};

export const formatMilkScore = (value: string): string => {
  enum MilkScoreLabels {
    'No Risk' = 0,
    'Low Risk' = 1,
    'Medium Risk' = 2,
    'High Risk' = 3,
    'Critical Risk' = 4
  }
  // Randomized for now. TODO: Get this enum somewhere else in the stack - should be somehow common to all applications.
  return (
    (Object.values(MilkScoreLabels)[parseInt(value, 10)] as string) || 'Unknown'
  );
};

export const formatMilkScoreAbbr = (value: string): string => {
  //milk score abbriviated version
  enum MilkScoreLabels {
    'LOW' = 1,
    'MED' = 2,
    'HI' = 3,
    'CRIT' = 4
  }
  // Randomized for now. TODO: Get this enum somewhere else in the stack - should be somehow common to all applications.
  return (Object.values(MilkScoreLabels)[+value - 1] as string) || 'Unknown';
};

export const formatMilkScoreForExport = (
  value: number,
  complianceMethod: string
): string => {
  const score =
    complianceMethod == Constants.COMPLIANCE_METHOD_FONTERRA
      ? formatMilkScore(Math.round(value).toString())
      : formatMilkScoreColour(Math.round(value) as rating, false);
  return score == 'none' || score == 'No Risk' || !score
    ? ''
    : toTitleCase(score);
};

export const formatMilkVolume = (value: string): string => {
  return Math.round(+value).toLocaleString() + 'L';
};

export const formatMilkingTimeWindow = (value: string): string => {
  const bounds = value.split(' - ');
  const lowerBound = bounds[0];
  const upperBound = bounds[1];
  // Temporary again.
  const lowerBoundHour = formatToHourAndMinutes(lowerBound);
  const upperBoundHour = formatToHourAndMinutes(upperBound);
  return lowerBoundHour + ' - ' + upperBoundHour;
};

export const formatLastPickup = (value: string): string => {
  return relativeDay(dayjs(value).unix());
};

export const formatLastMilking = (value: number): string => {
  return relativeDay(value);
};

export const formatFeedTankLevel = (value: string) => {
  return Math.round(parseFloat(value)) + '%';
};

export const formatFeedRemaining = (value: string) => {
  return Math.round(parseFloat(value)) + ' ' + getUnit();
};

export const formatFeedCapacity = (value: string) => {
  return parseFloat(value).toLocaleString() + ' ' + getUnit();
};

export const formatFeedDailyUse = (value: string) => {
  return Math.round(parseFloat(value)).toLocaleString() + ' ' + getUnit();
};

export const formatFeedDaysToEmpty = (value: string) => {
  return Math.round(parseFloat(value)) === 1
    ? Math.round(parseFloat(value)) + ' day'
    : Math.round(parseFloat(value)) + ' days';
};

export const formatFeedLastDelivery = (value: string) => {
  return timeAgo(value);
};

export const formatFuelDaysToEmpty = (value: number) => {
  return value ? getDaysFromUnix(value) : null;
};

export const formatWaterFlow = (value: number) => {
  return value != null ? value.toLocaleString() + 'L/s' : '';
};

export const formatWaterUsage = (value: number) => {
  return value != null ? value.toLocaleString() + 'L' : '';
};

export const formatDaysTillEmpty = (value: number) => {
  return `${Math.round(value) > 365 ? '365+' : Math.round(value)} ${
    Math.round(value) == 1 ? ' day' : ' days'
  }`;
};

export const formatFuelAlert = (settings: any, percent: number) => {
  if (
    settings != null &&
    settings?.lowLevelThreshold != null &&
    settings?.criticalLevelThreshold != null
  ) {
    if (percent > settings?.lowLevelThreshold) {
      return 0;
    } else if (percent > settings?.criticalLevelThreshold) {
      return 1;
    } else {
      return 2;
    }
  } else {
    const fuelCustomSettings = CustomStore.customEntitySettings as FuelCustomSettingsData;
    if (
      !fuelCustomSettings ||
      !fuelCustomSettings?.lowLevelThreshold ||
      !fuelCustomSettings?.criticalLevelThreshold
    ) {
      return 0;
    }
    if (percent > fuelCustomSettings?.lowLevelThreshold) {
      return 0;
    } else if (percent > fuelCustomSettings?.criticalLevelThreshold) {
      return 1;
    } else {
      return 2;
    }
  }
};

export const formatWaterAlert = (currentUsage: number) => {
  const globalUsageMax = 1; //just a pretend value yeet
  if (currentUsage > globalUsageMax) {
    return 1;
  } else {
    return 0;
  }
};

export const formatTimeOfAlert = (
  settings: any,
  percent: number,
  volumeTimestamps: LevelHistory
) => {
  const alert = formatFuelAlert(settings, percent);
  if (volumeTimestamps != null) {
    if (
      settings != null &&
      settings?.lowLevelThreshold != null &&
      settings?.criticalLevelThreshold != null
    ) {
      const lowLevelNearest5 = Math.round(settings?.lowLevelThreshold / 5) * 5;
      const criticalLevelNearest5 =
        Math.round(settings?.criticalLevelThreshold / 5) * 5;
      if (alert == 2) {
        return volumeTimestamps[criticalLevelNearest5 as keyof LevelHistory];
      } else if (alert == 1) {
        return volumeTimestamps[lowLevelNearest5 as keyof LevelHistory];
      }
    } else {
      const fuelCustomSettings = CustomStore.customEntitySettings as FuelCustomSettingsData;

      if (
        !fuelCustomSettings ||
        !fuelCustomSettings.lowLevelThreshold ||
        !fuelCustomSettings.criticalLevelThreshold
      ) {
        return null;
      }
      const globalLowLevelNearest5 =
        Math.round(fuelCustomSettings?.lowLevelThreshold / 5) * 5;
      const globalCriticalLevelNearest5 =
        Math.round(fuelCustomSettings?.criticalLevelThreshold / 5) * 5;
      if (alert == 2) {
        return volumeTimestamps[
          globalCriticalLevelNearest5 as keyof LevelHistory
        ];
      } else if (alert == 1) {
        return volumeTimestamps[globalLowLevelNearest5 as keyof LevelHistory];
      }
    }
  }

  return null;
};

export const formatWaterTimeOfAlert = () => {
  return 1643746042; //currently no data for time of alert
};

export const formatComment = (
  entity: ProductAPI,
  lastDeliveryTs: number | null
) => {
  const comment =
    typeof entity.entityComments === 'string'
      ? (JSON.parse(entity.entityComments ?? '{}') as Comment)
      : ((entity.entityComments ?? {}) as Comment);
  if (Object.keys(comment).length !== 0) {
    if (lastDeliveryTs == null || comment.timestamp > lastDeliveryTs) {
      return comment;
    }
  }

  return null;
};

export const formatVatState = (state: string, supplier: string) => {
  if (supplier == 'F') {
    return state;
  } else {
    return state?.toLocaleLowerCase().includes('supply') ? 'supply' : state;
  }
};

export const decamelizeFunction = (str: string) => {
  return toDecamelize(str);
};
