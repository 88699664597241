import User from '@/store/modules/User';
import dayjs from 'dayjs';

/**
 * Checks whether the timestamp for contains milk is valid
 * Will return false if the value is more than 4 days old
 * (345,600 seconds)
 */
export function validityContainsMilk(timestamp: string) {
  const difference = +dayjs().unix() - +timestamp;
  return difference < 345600;
}

/**
 * Same as above function but may have more functionality to
 * add eventually so it is in its own function
 */
export function validityVatVolume(timestamp: string) {
  const difference = +dayjs().unix() - +timestamp;
  return difference < 345600;
}

export function outdatedValidity(timestamp: number | undefined) {
  if (timestamp != undefined) {
    const difference = +dayjs().unix() - timestamp;
    return difference > 32400;
  } else {
    return true;
  }
}

export function isAdmin() {
  let isAdmin = false;
  User.currentUser?.orgRoles.forEach(role => {
    if (role.id == User.orgId && role.role == 'partner.admin') {
      isAdmin = true;
    }
  });
  return isAdmin;
}
