import CustomField from './CustomField';

export interface Filters {
  volumeRange: number[];
  capacityRange: number[];
  customSiteFilters: CustomFieldFilter[];
  customEntityFilters: CustomFieldFilter[];
}

export interface SavedFuelFilters {
  type: string[];
  volumeRange: (number | null)[];
  capacityRange: (number | null)[];
  ullageRange: (number | null)[];
  customSiteFilters: CustomFieldFilter[];
  customEntityFilters: CustomFieldFilter[];
  customTankFilters?: CustomFieldFilter[];
  onlyFaults: boolean;
  onlyOutdated: boolean;
}

export interface FuelFilters extends Filters {
  type: string[];
  ullageRange: number[];
  onlyFaults: boolean;
  onlyOutdated: boolean;
}

export interface SavedMilkFilters {
  age: number;
  volumePercent: number[];
  volumeRange: (number | null)[];
  capacityRange: (number | null)[];
  customSiteFilters: CustomFieldFilter[];
  customEntityFilters: CustomFieldFilter[];
  customVatFilters?: CustomFieldFilter[];
  risk: number;
  score: string[];
  complianceMethod: string[];
  onlySupply: boolean;
  temperature: 'above' | 'below' | '';
  hideRepair: boolean;
}

export interface MilkFilters extends Filters {
  age: number;
  volumePercent: number[];
  risk: number;
  score: string[];
  onlySupply: boolean;
  temperature: 'above' | 'below' | '';
  hideRepair: boolean;
  complianceMethod: string[];
}

export interface SavedWaterFilters {
  currentUsageRange: (number | null)[];
  usageTodayRange: (number | null)[];
  usageYesterdayRange: (number | null)[];
  customSiteFilters: CustomFieldFilter[];
  customEntityFilters: CustomFieldFilter[];
}

export interface WaterFilters extends Filters {
  currentUsageRange: number[];
  usageTodayRange: number[];
  usageYesterdayRange: number[];
}

export interface SavedFeedFilters {
  storage: string;
  type: string[];
  volumeRange: (number | null)[];
  capacityRange: (number | null)[];
  weightRange: (number | null)[];
  weightCapacityRange: (number | null)[];
  customSiteFilters: CustomFieldFilter[];
  customEntityFilters: CustomFieldFilter[];
  customFeedTankFilters?: CustomFieldFilter[];
}

export interface FeedFilters extends Filters {
  weightRange: number[];
  weightCapacityRange: number[];
  storage: string;
  type: string[];
}

export interface CustomFieldFilter {
  field: CustomField;
  selection: string[];
}

export type AppFilters = FuelFilters | MilkFilters | FeedFilters;
export type SavedAppFilters =
  | SavedFuelFilters
  | SavedMilkFilters
  | SavedFeedFilters;

export const milkFilterDefaults: MilkFilters = {
  age: 0,
  volumeRange: [],
  volumePercent: [],
  capacityRange: [],
  customSiteFilters: [],
  customEntityFilters: [],
  risk: 0,
  score: [],
  onlySupply: false,
  temperature: '',
  hideRepair: false,
  complianceMethod: []
};

export const fuelFilterDefaults: FuelFilters = {
  type: [],
  volumeRange: [],
  capacityRange: [],
  ullageRange: [],
  customSiteFilters: [],
  customEntityFilters: [],
  onlyFaults: false,
  onlyOutdated: false
};

export const feedFilterDefaults: FeedFilters = {
  type: [],
  volumeRange: [],
  capacityRange: [],
  weightRange: [],
  weightCapacityRange: [],
  customSiteFilters: [],
  customEntityFilters: [],
  storage: ''
};

export const waterFilterDefaults: WaterFilters = {
  volumeRange: [],
  capacityRange: [],
  currentUsageRange: [],
  usageTodayRange: [],
  usageYesterdayRange: [],
  customSiteFilters: [],
  customEntityFilters: []
};
