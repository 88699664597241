import MilkScore from '@/store/models/MilkScore';
import {
  FeedMetrics,
  FuelMetricsTank,
  MilkMetrics,
  Product,
  WaterMetricsSupply
} from '@/store/models/Product';
import dayjs from 'dayjs';
import { getEntityName, getUnit } from '../AppName';
import { Constants } from '../Constants';
import { getDateFromUnix, relativeDay } from '../DateUtils';
import { getFormattedFeedAmount, isTonnes, isWeight } from '../FeedUtils';
import {
  formatAgeMilk,
  formatDaysTillEmpty,
  formatLastMilking,
  formatLastPickup,
  formatMilkScoreAbbr,
  formatWaterFlow,
  formatWaterUsage
} from '../TableFormatters';

//Generic value
export const formatPercentFullDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | MilkMetrics | FeedMetrics;
  return Math.round(metrics.percentFull || 0) + '%';
};

//Milk values
export const formatMilkVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.vatVolume
    ? metrics.containsMilk
      ? Math.round(metrics.vatVolume).toLocaleString() + 'L'
      : metrics.containsMilk == null
      ? '-'
      : 'Empty'
    : metrics.containsMilk == null
    ? '-'
    : 'Empty';
};

export const formatLastCollectionDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.lastPickup
    ? formatLastPickup(metrics.lastPickup).split(' ')[0]
    : '-';
};

export const formatLastCollectionDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.lastPickup ? formatLastPickup(metrics.lastPickup) : '-';
};

export const formatAgeOfMilkDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics && metrics.milkEnteredVat && metrics.containsMilk
    ? formatAgeMilk(metrics.milkEnteredVat)
    : !entity.metrics || metrics.containsMilk == null
    ? '-'
    : metrics.containsMilk
    ? '-'
    : 'Empty';
};

export const formatAgeOfMilkDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.milkEnteredVat && metrics.containsMilk
    ? `Milk entered ${getEntityName(false)} ` +
        formatLastPickup(metrics.milkEnteredVat)
    : metrics.containsMilk
    ? 'No data recorded'
    : 'As at ' + formatLastPickup(dayjs().format());
};

export const formatMilkPercentFullDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.containsMilk
    ? formatPercentFullDisplay(entity)
    : metrics.containsMilk == null
    ? '-'
    : '0%';
};

export const formatLastMilkingDisplay = (entity: Product) => {
  const metrics = entity.metrics as MilkMetrics;
  return metrics.lastMilking && metrics.lastMilking.timestamp
    ? metrics.lastMilking.type == 'start'
      ? 'In Progress'
      : formatLastMilking(metrics.lastMilking.timestamp)
    : '';
};

export const formatMilkScoreDisplay = (
  entity: Product,
  milkScore: MilkScore
) => {
  const metrics = entity.metrics as MilkMetrics;
  if (milkScore.rating && milkScore.rating >= 1 && metrics.containsMilk) {
    if (entity.supplier == 'F') {
      return formatMilkScoreAbbr(milkScore.rating.toString());
    } else {
      return Math.round(milkScore.rating);
    }
  } else {
    return '';
  }
};

export const checkMilkMetricUnavailable = (
  entity: Product,
  field: keyof MilkMetrics
) => {
  const metrics = entity.metrics as MilkMetrics;
  return !metrics || metrics.containsMilk == null || metrics[field] == null;
};

export const checkWaterMetricUnavailable = (
  entity: Product,
  field: keyof WaterMetricsSupply
) => {
  const metrics = entity.metrics as WaterMetricsSupply;
  return !metrics || metrics[field] == null;
};

//Fuel and Feed values
export const formatVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics
    ? metrics.volume > 0 && 100 > metrics.percentFull
      ? `${metrics.volume.toLocaleString()} ${getUnit(entity)}`
      : ''
    : '';
};

export const formatFeedVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics
    ? metrics.volume > 0 && 100 > metrics.percentFull
      ? getFormattedFeedAmount(metrics.volume, entity)
      : ''
    : '';
};

export const formatFillVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.fillVolume != null
    ? Math.round(metrics.fillVolume || 0).toLocaleString() + getUnit(entity)
    : '-';
};

export const formatAvailableCapacityDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.fillVolume != null
    ? getFormattedFeedAmount(metrics.fillVolume, entity)
    : '-';
};

export const formatLastRefillVolumeDisplay = (entity: Product) => {
  const metrics = entity.metrics as FeedMetrics;
  if (!metrics) {
    return '-';
  }
  let lastDelivery = null;
  if (isWeight(entity)) {
    // Show tonnage, i.e. lastDeliveryWeight
    lastDelivery = metrics.lastDeliveryWeight;
  } else {
    lastDelivery = metrics.lastDeliveryVolume;
  }
  return lastDelivery != null
    ? getFormattedFeedAmount(lastDelivery ?? 0, entity)
    : '-';
};

export const formatLastRefillDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics &&
    metrics.lastDeliveryTimestamp != null &&
    metrics.lastDeliveryTimestamp != 0
    ? relativeDay(metrics.lastDeliveryTimestamp)
    : '-';
};

export const formatLastRefillTileDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.lastDeliveryTimestamp != null
    ? `Last refill ${relativeDay(metrics.lastDeliveryTimestamp)}`
    : '';
};

export const formatFeedDailyUseDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  if (!metrics) {
    return '-';
  }
  if (isWeight(entity) && (metrics as FeedMetrics).shortDailyUseWeight) {
    let shortDailyUseWeight: number =
      (metrics as FeedMetrics).shortDailyUseWeight ?? 0;
    if (!isTonnes(shortDailyUseWeight, entity)) {
      shortDailyUseWeight = Math.round(shortDailyUseWeight);
    }
    return getFormattedFeedAmount(shortDailyUseWeight, entity);
  }
  return metrics.dailyUse != null
    ? getFormattedFeedAmount(metrics.dailyUse, entity, false)
    : '-';
};

export const formatDailyUseDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.dailyUse != null
    ? metrics.dailyUse.toLocaleString() + getUnit(entity)
    : '-';
};

export const formatDailyUseDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.dailyUseTs != null
    ? `As at ${relativeDay(metrics.dailyUseTs || 0)}`
    : '';
};

export const formatDaysTillEmptyDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.daysTillEmpty != null
    ? formatDaysTillEmpty(metrics.daysTillEmpty)
    : '-';
};

export const formatDaysTillEmptyDateDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  return metrics && metrics.estimatedEmptyTimestamp
    ? `Empty on
    ${getDateFromUnix(metrics.estimatedEmptyTimestamp)}`
    : '';
};

export const formatVolumeWithCapacityDisplay = (entity: Product) => {
  const metrics = entity.metrics as FuelMetricsTank | FeedMetrics;
  if (
    metrics &&
    metrics.volume > 0 &&
    100 > metrics.percentFull &&
    metrics.nominalVolume
  ) {
    return `${metrics.volume.toLocaleString()}${getUnit(
      entity
    )} /  ${metrics.nominalVolume.toLocaleString()}${getUnit(entity)}`;
  } else {
    return '-';
  }
};

export const checkFuelFeedMetricUnavailable = (
  entity: Product,
  field: (
    | 'volume'
    | 'percentFull'
    | 'fillVolume'
    | 'daysTillEmpty'
    | 'dailyUse'
    | 'lastDeliveryVolume'
  )[]
) => {
  const metrics = entity.metrics as FeedMetrics;
  let unavailable = false;
  field.forEach(f => {
    if (!metrics || metrics[f] == null) {
      unavailable = true;
    }
  });
  return unavailable;
};

export const isLive = (entity: Product) => {
  if (entity.status != Constants.ENTITY_STATUS_LIVE) {
    return false;
  }
  return true;
};

// Water values
export const formatCurrentUsageDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterMetricsSupply;
  return metrics.currentUsage != null
    ? formatWaterFlow(metrics.currentUsage)
    : '-';
};

export const formatUsageTodayDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterMetricsSupply;
  return metrics.usageToday != null
    ? formatWaterUsage(metrics.usageToday)
    : '-';
};

export const formatUsageYesterdayDisplay = (entity: Product) => {
  const metrics = entity.metrics as WaterMetricsSupply;
  return metrics.usageYesterday != null
    ? formatWaterUsage(metrics.usageYesterday)
    : '-';
};
