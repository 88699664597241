import { ComplianceSettings } from '@/store/models/MilkScore';
import { FuelMetricsTank, MilkMetrics, Product } from '@/store/models/Product';
import { isApp } from './AppName';
import { Constants } from './Constants';
import { formatHours, getUnixFromDate } from './DateUtils';
import {
  getScoreTs,
  isAgeCrossThreshold,
  isScoreCrossThreshold
} from './MilkScoreUtils';

export function isWarningMilkScore(product: Product): boolean {
  const metrics = product.metrics as MilkMetrics;
  if (metrics.containsMilk) {
    return (
      isScoreCrossThreshold(
        metrics.milkScores,
        product.entityCustomSettingsData as ComplianceSettings
      ) ?? false
    );
  }
  return false;
}

export function isWarningMilkAge(product: Product): boolean {
  const age = formatHours((product.metrics as MilkMetrics).milkEnteredVat);
  if ((product.metrics as MilkMetrics).containsMilk) {
    return (
      isAgeCrossThreshold(
        age,
        product.entityCustomSettingsData as ComplianceSettings
      ) ?? false
    );
  }
  return false;
}

export function getAlertTimestamp(product: Product) {
  let alertTs = 0;
  if (isApp(Constants.PRODUCT_TYPE_MILK)) {
    if (isWarningMilkScore(product)) {
      alertTs =
        getScoreTs(
          (product.metrics as MilkMetrics).milkScores,
          product.entityCustomSettingsData as ComplianceSettings
        ) ?? 0;
    } else if (isWarningMilkAge(product)) {
      alertTs =
        getUnixFromDate((product.metrics as MilkMetrics).milkEnteredVat) ?? 0;
    }
  } else {
    alertTs = (product?.metrics as FuelMetricsTank).alertTs ?? 0;
  }
  return alertTs;
}
