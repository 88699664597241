import CustomField from '@/store/models/CustomField';
import {
  CustomFieldFilter,
  feedFilterDefaults,
  FeedFilters,
  fuelFilterDefaults,
  FuelFilters,
  milkFilterDefaults,
  MilkFilters
} from '@/store/models/Filters';
import { getAppName, isApp } from '@/utils/AppName';
import { Constants } from '@/utils/Constants';

export interface CustomFieldObjectPreferences {
  products: CustomField[];
  tanks?: CustomField[];
  vats?: CustomField[];
  feed_tanks?: CustomField[];
}
export interface CustomFieldPreferences {
  products: string[];
  tanks?: string[];
  vats?: string[];
  feed_tanks?: string[];
}

export interface FuelAlertCustomFilters extends FuelFilters {
  customTankFilters?: CustomFieldFilter[];
}

export interface MilkAlertCustomFilters extends MilkFilters {
  customVatFilters?: CustomFieldFilter[];
}

export interface FeedAlertCustomFilters extends FeedFilters {
  customFeedTankFilters?: CustomFieldFilter[];
}

export interface AlertSummaryEmail {
  partnerId: number | null;
  fixedSchedule: string[] | null;
  periodSchedule: number | null;
  onlySendNew: boolean;
  lastRunTs?: number | null;
}

export interface FuelAlertSummaryEmail extends AlertSummaryEmail {
  customFilter: FuelAlertCustomFilters;
  customFields: CustomFieldPreferences;
}

export interface MilkAlertSummaryEmail extends AlertSummaryEmail {
  customFilter: MilkAlertCustomFilters;
  customFields: CustomFieldPreferences;
}

export interface FeedAlertSummaryEmail extends AlertSummaryEmail {
  customFilter: FeedAlertCustomFilters;
  customFields: CustomFieldPreferences;
}

export type AppAlertSummaryEmail =
  | MilkAlertSummaryEmail
  | FuelAlertSummaryEmail
  | FeedAlertSummaryEmail;

export interface FuelPartnerPreferences {
  alertSummaryEmail: FuelAlertSummaryEmail;
}

export interface MilkPartnerPreferences {
  alertSummaryEmail: MilkAlertSummaryEmail;
}

export interface FeedPartnerPreferences {
  alertSummaryEmail: FeedAlertSummaryEmail;
}

export interface PageEmailPreferences extends AlertSummaryEmail {
  customFilter:
    | MilkAlertCustomFilters
    | FuelAlertCustomFilters
    | FeedAlertCustomFilters;
  customFields: CustomFieldObjectPreferences;
}

export interface PartnerPreferences {
  fuel?: FuelPartnerPreferences;
  milk?: MilkPartnerPreferences;
  feed?: FeedPartnerPreferences;
}

export default interface Preferences {
  partner: PartnerPreferences;
}

export const milkCustomFilterDefaults: MilkAlertCustomFilters = {
  ...milkFilterDefaults,
  customVatFilters: []
};

export const fuelCustomFilterDefaults: FuelAlertCustomFilters = {
  ...fuelFilterDefaults,
  customTankFilters: []
};

export const feedCustomFilterDefaults: FeedAlertCustomFilters = {
  ...feedFilterDefaults,
  customFeedTankFilters: []
};

export const pageEmailPreferencesDefaults: () => PageEmailPreferences = () => {
  let customFilter;
  switch (getAppName(false)) {
    case Constants.PRODUCT_TYPE_MILK:
      customFilter = milkCustomFilterDefaults;
      break;
    case Constants.PRODUCT_TYPE_FUEL:
      customFilter = fuelCustomFilterDefaults;
      break;
    case Constants.PRODUCT_TYPE_FEED:
      customFilter = feedCustomFilterDefaults;
      break;
    default:
      customFilter = fuelCustomFilterDefaults;
  }
  return {
    partnerId: null,
    fixedSchedule: null,
    periodSchedule: null,
    onlySendNew: false,
    customFilter: customFilter,
    customFields: {
      products: [],
      tanks: [],
      vats: [],
      feed_tanks: []
    }
  };
};
