import { MilkFilters } from '@/store/models/Filters';
import { ComplianceSettings } from '@/store/models/MilkScore';
import { MilkMetrics, Product } from '@/store/models/Product';
import dayjs from 'dayjs';
import { isWarningMilkAge, isWarningMilkScore } from '../AlertUtils';
import { getListItem } from '../LocalStorageUtils';
import { getComplianceScore, getMethod } from '../MilkScoreUtils';
import { formatMilkScoreColour } from '../TableFormatters';
import { FiltersClass } from './FiltersClass';

export class MilkFiltersClass extends FiltersClass<MilkFilters> {
  constructor() {
    super('milk', {
      age: 0,
      volumeRange: [0, 0],
      volumePercent: [0, 100],
      capacityRange: [0, 0],
      customSiteFilters: [],
      customEntityFilters: [],
      risk: 0,
      score: [],
      onlySupply: false,
      temperature: '',
      hideRepair: false,
      complianceMethod: []
    });
  }

  public checkMilkAlertFilter(product: Product) {
    let hasAlert = false;
    let savedAlertType = getListItem('milkAlertType');
    if (!savedAlertType || !(savedAlertType.length > 0)) {
      savedAlertType = ['score'];
    }

    if (savedAlertType.includes('age') && savedAlertType.includes('score')) {
      hasAlert = isWarningMilkScore(product) || isWarningMilkAge(product);
    } else if (
      savedAlertType.includes('age') &&
      !savedAlertType.includes('score')
    ) {
      hasAlert = isWarningMilkAge(product);
    } else if (
      !savedAlertType.includes('age') &&
      savedAlertType.includes('score')
    ) {
      hasAlert = isWarningMilkScore(product);
    }

    return hasAlert;
  }

  public filterAlerts(
    products: Product[],
    stateFilter = ['actioned', 'unactioned']
  ): Product[] {
    return products?.filter(product => {
      return (
        this.checkMilkAlertFilter(product) &&
        this.checkStateFilter(stateFilter, product)
      );
    });
  }

  public filterProducts(products: Product[]) {
    let filtered = [...products];
    if (this.filters.volumeRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          !(product.metrics as MilkMetrics).containsMilk ||
          ((product.metrics as MilkMetrics).containsMilk &&
            +(product.metrics as MilkMetrics).vatVolume <=
              this.filters.volumeRange[1])
      );
    }

    if (this.filters.volumeRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as MilkMetrics).containsMilk &&
          +(product.metrics as MilkMetrics).vatVolume >
            this.filters.volumeRange[0]
      );
    }

    if (this.filters.volumePercent[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as MilkMetrics).containsMilk &&
          +Math.round(
            ((product.metrics as MilkMetrics).vatVolume /
              +(product.capacity ?? 0)) *
              100
          ) > this.filters.volumePercent[0]
      );
    }

    if (this.filters.volumePercent[1] < 100) {
      filtered = filtered?.filter(
        product =>
          !(product.metrics as MilkMetrics).containsMilk ||
          ((product.metrics as MilkMetrics).containsMilk &&
            +Math.round(
              ((product.metrics as MilkMetrics).vatVolume /
                +(product.capacity ?? 0)) *
                100
            ) <= this.filters.volumePercent[1])
      );
    }

    if (this.filters.capacityRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          !(product.metrics as MilkMetrics).containsMilk ||
          ((product.metrics as MilkMetrics).containsMilk &&
            +(product.capacity ?? 0) <= this.filters.capacityRange[1])
      );
    }
    if (this.filters.capacityRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as MilkMetrics).containsMilk &&
          +(product.capacity ?? 0) > this.filters.capacityRange[0]
      );
    }

    // min milk age
    if (this.filters.age > 0) {
      filtered = filtered?.filter(product => {
        const hours = dayjs().diff(
          dayjs((product.metrics as MilkMetrics).milkEnteredVat),
          'h'
        );
        return (
          hours >= +this.filters.age &&
          (product.metrics as MilkMetrics).containsMilk
        );
      });
    }

    //score
    if (this.filters.score && this.filters.score.length > 0) {
      filtered = filtered?.filter(product => {
        // get the relevant score object based on the selected compliance method for the entity
        const methodScore = getComplianceScore(
          (product?.metrics as MilkMetrics).milkScores,
          product?.entityCustomSettingsData as ComplianceSettings
        );
        // get the colour definition (this will be either none, green, yellow, orange or red)
        const colour = formatMilkScoreColour(
          methodScore?.rating ?? null,
          !!methodScore?.areaOutOfSpec
        );
        return (
          (product.metrics as MilkMetrics).containsMilk &&
          this.filters.score.includes(colour)
        );
      });
    }

    // compliance method
    if (this.filters.complianceMethod.length != 0) {
      filtered = filtered?.filter(product =>
        this.filters.complianceMethod.includes(
          getMethod(product.entityCustomSettingsData as ComplianceSettings)
        )
      );
    }

    if (this.filters.onlySupply) {
      filtered = filtered?.filter(product => product.state == 'supply');
    }

    if (this.filters.hideRepair) {
      filtered = filtered?.filter(product => product.faultStatus != 'repair');
    }

    // min milk age
    if (this.filters.age > 0) {
      filtered = filtered?.filter(product => {
        const hours = dayjs().diff(
          dayjs((product.metrics as MilkMetrics).milkEnteredVat),
          'h'
        );
        return (
          hours >= +this.filters.age &&
          (product.metrics as MilkMetrics).containsMilk
        );
      });
    }

    //vat temperature below or above 6 degrees
    if (
      this.filters.temperature == 'below' ||
      this.filters.temperature == 'above'
    ) {
      filtered = filtered?.filter(product => {
        if (
          this.filters.temperature == 'below' &&
          (product.metrics as MilkMetrics).vatTemperature < 6
        ) {
          return true;
        } else if (
          this.filters.temperature == 'above' &&
          (product.metrics as MilkMetrics).vatTemperature >= 6
        ) {
          return true;
        }
        return false;
      });
    }

    filtered = super.filterProducts(filtered);
    return filtered;
  }

  public get filtersSet(): boolean {
    return (
      super.filtersSet ||
      this.filters.volumePercent[0] > 0 ||
      this.filters.volumePercent[1] < 100 ||
      this.filters.score.length != 0 ||
      this.filters.risk > 0 ||
      this.filters.age > 0 ||
      this.filters.complianceMethod.length != 0 ||
      this.filters.onlySupply ||
      this.filters.temperature !== '' ||
      this.filters.hideRepair
    );
  }
}
