import { StringKeyObject } from '@/utils/types/StringKeyObject';
import Comment from './Comment';
import { FuelCustomSettingsData } from './CustomSettingsData';
import LastMilking from './LastMilking';
import LevelHistory from './LevelHistory';
import MapTagValue from './MapTagValue';
import MilkScore, { ComplianceSettings } from './MilkScore';
import { AmountMetric } from '@/utils/types/AmountMetric';

export interface FeedMetrics {
  nominalVolume: number;
  dailyUse: number;
  feedType: string;
  feedTypeLevelMetric?: AmountMetric;
  storage: string;
  percentFull: number;
  volume: number;
  weight: number;
  fillVolume: number;
  calculatedAt: string;
  lastDeliveryTimestamp: number;
  estimatedEmptyTimestamp: number;
  shortEstimatedEmptyDate: string;
  longEstimatedEmptyDate: string;
  daysTillEmpty: number;
  volumeTs?: number;
  dailyUseTs?: number;
  percentFullTs?: number;
  alert?: number;
  alertTs?: number;
  levelHistory?: LevelHistory;
  outdated?: boolean;
  longDailyUse: number;
  shortDailyUse: number;
  shortDailyUseWeight?: number;
  lastDeliveryTs: number;
  levelHistoryTs: number;
  longDailyUseTs: number;
  shortDailyUseTs: number;
  lastDeliveryVolume: number;
  lastDeliveryVolumeTs: number;
  lastDeliveryWeight?: number;
  longEstimatedEmptyDateTs: number;
  shortEstimatedEmptyDateTs: number;
}
export interface MilkMetrics {
  milkScore: number;
  milkScores: MilkScore[] | null;
  vatVolume: number;
  milkingNext: string;
  milkingTimeWindow: string;
  milkEnteredVat: string;
  lastPickup: string;
  lastMilking: LastMilking;
  containsMilk: boolean | null;
  capacity: number;
  state: string;
  vatTemperature: number;
  alert?: number;
  percentFull: number;
  milkScoresTs: number;
}
export interface FuelMetricsTank {
  nominalVolume: number;
  dailyUse: number;
  fuelType: string;
  percentFull: number;
  volume: number;
  fillVolume: number;
  calculatedAt: string;
  lastDeliveryTimestamp: number;
  estimatedEmptyTimestamp: number;
  daysTillEmpty: number;
  workingDaysTillEmpty: number;
  volumeTs?: number;
  dailyUseTs?: number;
  percentFullTs?: number;
  alert?: number;
  alertTs?: number;
  safeFillVolume?: number;
  levelHistory?: LevelHistory;
  outdated?: boolean;
}

export interface WaterMetricsSupply {
  currentUsage: number;
  usageToday: number;
  usageYesterday: number;
  alert?: number;
}

export interface Product {
  productId: number;
  partnerId: number;
  supplierNumber: string;
  customFieldData: StringKeyObject | null;
  contributionTier?: string;
  entityCustomFieldData: StringKeyObject | null;
  customSettingsData: StringKeyObject | null;
  entityCustomSettingsData: FuelCustomSettingsData | ComplianceSettings | null;
  name: string;
  address?: string;
  comments?: Comment;
  entityComments?: Comment;
  capacity?: number;
  siteName?: string;
  entityId: number;
  entities?: Product[];
  faultCustomerImpact?: string;
  latitude: string;
  longitude: string;
  largestCapacity?: number;
  supplier?: string;
  maxAge?: MapTagValue;
  maxScore?: MapTagValue;
  maxCurrentUsage?: MapTagValue;
  minDays?: MapTagValue;
  minPercent?: MapTagValue;
  metrics?: FeedMetrics | MilkMetrics | FuelMetricsTank | WaterMetricsSupply;
  partnerDefinedEntityId?: string;
  partnerDefinedEntityName?: string;
  partnerDefinedId?: string;
  partnerDefinedName?: string;
  faultStatus?: string;
  state?: string;
  status?: string;
  entityStatus?: string;
  isNew?: boolean;
}

export const MilkMetricsDefault: MilkMetrics = {
  milkScore: 0,
  milkScores: null,
  vatVolume: 0,
  milkingNext: '',
  milkingTimeWindow: '',
  milkEnteredVat: '',
  lastPickup: '',
  lastMilking: { type: '', state: '', timestamp: 0, volumeMilked: 0 },
  containsMilk: null,
  capacity: 0,
  state: '',
  vatTemperature: 0,
  percentFull: 0,
  milkScoresTs: 0
};

export const FuelMetricsDefault: FuelMetricsTank = {
  nominalVolume: 0,
  dailyUse: 0,
  fuelType: '',
  percentFull: 0,
  volume: 0,
  fillVolume: 0,
  calculatedAt: '',
  lastDeliveryTimestamp: 0,
  estimatedEmptyTimestamp: 0,
  daysTillEmpty: 0,
  workingDaysTillEmpty: 0,
  volumeTs: 0,
  dailyUseTs: 0,
  percentFullTs: 0,
  alert: 0,
  alertTs: 0,
  safeFillVolume: 0,
  levelHistory: {
    5: 0,
    10: 0,
    15: 0,
    20: 0,
    25: 0,
    30: 0,
    35: 0,
    40: 0,
    45: 0,
    50: 0,
    55: 0,
    60: 0,
    65: 0,
    70: 0,
    75: 0,
    80: 0,
    85: 0,
    90: 0
  },
  outdated: false
};

export const WaterMetricsDefault: any = {
  currentUsage: null,
  usageToday: null,
  usageYesterday: null
};

export const FeedMetricsDefault: FeedMetrics = {
  nominalVolume: 0,
  dailyUse: 0,
  feedType: '',
  storage: '',

  percentFull: 0,
  volume: 0,
  weight: 0,
  fillVolume: 0,
  calculatedAt: '',
  lastDeliveryTimestamp: 0,
  estimatedEmptyTimestamp: 0,
  shortEstimatedEmptyDate: '',
  longEstimatedEmptyDate: '',
  daysTillEmpty: 0,
  volumeTs: 0,
  dailyUseTs: 0,
  percentFullTs: 0,
  alert: 0,
  alertTs: 0,
  levelHistory: FuelMetricsDefault.levelHistory,
  outdated: false,
  longDailyUse: 0,
  shortDailyUse: 0,
  lastDeliveryTs: 0,
  levelHistoryTs: 0,
  longDailyUseTs: 0,
  shortDailyUseTs: 0,
  lastDeliveryVolume: 0,
  lastDeliveryVolumeTs: 0,
  longEstimatedEmptyDateTs: 0,
  shortEstimatedEmptyDateTs: 0
};
