import ProductAPI from '@/services/api/models/ProductAPI';
import MapTagValue from '@/store/models/MapTagValue';
import {
  FeedMetrics,
  FuelMetricsDefault,
  FuelMetricsTank,
  Product,
  WaterMetricsSupply
} from '@/store/models/Product';
import { Constants } from '../Constants';
import { ConvertCustomFields, ConvertCustomSettings } from '../CustomFields';
import { formatStatus, isNew } from '../FormattingUtils';
import { StringKeyObject } from '../types/StringKeyObject';
import { formatWaterFlow } from '../TableFormatters';

/**
 * Formats general entity fields
 */
export function formatEntitiesAPI(entity: ProductAPI) {
  return {
    ...entity,
    siteName: entity.partnerDefinedName
      ? entity.partnerDefinedName
      : entity.siteName,
    customFieldData: ConvertCustomFields(entity.customFieldData),
    entityCustomFieldData: ConvertCustomFields(entity.entityCustomFieldData),
    entityCustomSettingsData: ConvertCustomSettings(
      entity.entityCustomSettingsData
    ),
    isNew: isNew(entity.liveAt),
    status: formatStatus(entity.entityStatus)
  };
}

/**
 * Formats general entity metrics
 */
export function formatEntityMetrics(entity: ProductAPI) {
  let metrics;

  if (!entity.metrics || Object.keys(entity.metrics).length == 0) {
    metrics = FuelMetricsDefault;
  } else {
    metrics =
      typeof entity.metrics === 'string'
        ? JSON.parse(entity.metrics)
        : entity.metrics;
  }
  return metrics;
}

/**
 * Formats general entity fields for the grouping by product functions
 */
export function formatGroupedEntity(entity: Product) {
  return {
    ...entity,
    name: entity.name,
    entityId: entity.entityId,
    capacity: entity.capacity,
    entityCustomFieldData: entity.entityCustomFieldData,
    entityCustomSettingsData: entity.entityCustomSettingsData,
    partnerDefinedEntityId: entity.partnerDefinedEntityId,
    partnerDefinedEntityName: entity.partnerDefinedEntityName,
    faultStatus: entity.faultStatus,
    status: entity.entityStatus ?? Constants.ENTITY_STATUS_LIVE
  };
}

/**
 * Combines entity custom fields by comma separation
 */
export function getCombinedEntityCustomFields(
  combinedEntityCustomFields: StringKeyObject,
  entityCustomFields: StringKeyObject | null
) {
  const combined = { ...combinedEntityCustomFields };
  Object.entries(entityCustomFields ?? {}).forEach(entry => {
    if (entry[1] != null && combined[entry[0]] != null) {
      combined[entry[0]] += `, ${entry[1]}`;
    } else if (combined[entry[0]] == null) {
      combined[entry[0]] = entry[1];
    }
  });
  return combined;
}

/**
 * Groups list of generic entities by product id to create a list of products with entites.
 * Exported as map.
 */
export function getProductMap(
  entities: Product[],
  groupKey: keyof Product = 'productId'
) {
  const map = new Map();
  entities.forEach((item: Product) => {
    const collection = map.get(item[groupKey]);
    if (!collection) {
      map.set(item[groupKey], {
        ...item,
        largestCapacity: item.capacity,
        entities: [
          {
            ...item,
            metrics: {
              ...item.metrics,
              capacity: item.capacity
            }
          }
        ]
      });
    } else {
      collection.entities.push({
        ...item,
        metrics: {
          ...item.metrics,
          capacity: item.capacity
        }
      });
      if (item.capacity && item.capacity > collection.largestCapacity) {
        collection.largestCapacity = item.capacity;
      }
    }
  });
  return map;
}

/**
 * Groups list of generic entities by product id to create a list of products with entites.
 * Exported as map.
 */
export function getGroupedProducts(entities: Product[]) {
  return getProductMap(entities, 'productId');
}

/**
 * Groups list of generic entities by product id to create a list of products with entites
 */
export function groupProducts(entities: Product[]) {
  return Array.from(getGroupedProducts(entities)).map(product => product[1]);
}

/**
 * Returns the minimum value, the display value and the index of the entity with that value.
 * This is used by fuel and feed product grouping functions
 */

export function getMinValue(
  currentTank: Product,
  index: number,
  previous: MapTagValue | null,
  metric: 'daysTillEmpty' | 'percentFull'
): MapTagValue {
  if (currentTank.metrics as FuelMetricsTank | FeedMetrics) {
    const value = (currentTank.metrics as FuelMetricsTank | FeedMetrics)[
      metric
    ];
    const display =
      metric == 'daysTillEmpty'
        ? (value > 365 ? '>365' : value) + 'd'
        : Math.round(value) + '%';
    if (index == 0 || previous === null) {
      return {
        value: value,
        display: value ? display : '-',

        index: 0
      };
    } else {
      if (value && previous?.value > value) {
        return {
          value: value,
          display: value ? display : '-',
          index: index
        };
      } else {
        return previous;
      }
    }
  }
  return {
    value: 0,
    display: '-',
    index: 0
  };
}

/**
 * Returns the maximum value, the display value and the index of the entity with that value.
 * This is used by the water product grouping function
 */

export function getMaxValue(
  currentTank: Product,
  index: number,
  previous: MapTagValue | null,
  metric: 'currentUsage'
): MapTagValue {
  if (currentTank.metrics as WaterMetricsSupply) {
    const value = (currentTank.metrics as WaterMetricsSupply)[metric];
    const display = formatWaterFlow(value);
    if (index == 0 || previous === null) {
      return {
        value: value,
        display: value ? display : '-',
        index: 0
      };
    } else {
      if (value && previous?.value < value) {
        return {
          value: value,
          display: value ? display : '-',
          index: index
        };
      } else {
        return previous;
      }
    }
  }
  return {
    value: 0,
    display: '-',
    index: 0
  };
}

/*
 * Returns the max value of a certain metric field for a list of products
 */
export function reduceMaxValue(products: Product[], field: string) {
  return products.reduce(
    (a, b) =>
      a < (b.metrics as any)?.[field] ? (b.metrics as any)?.[field] : a,
    0
  );
}
