import { WaterFilters } from '@/store/models/Filters';
import { Product, WaterMetricsSupply } from '@/store/models/Product';
import { FiltersClass } from './FiltersClass';
import { reduceMaxValue } from '../formatters/EnititesFormatter';

export class WaterFiltersClass extends FiltersClass<WaterFilters> {
  public maxUsageToday = 0;
  public maxUsageYesterday = 0;
  public maxCurrentUsage = 0;
  constructor() {
    super('water', {
      volumeRange: [0, 0],
      capacityRange: [0, 0],
      currentUsageRange: [0, 0],
      usageTodayRange: [0, 0],
      usageYesterdayRange: [0, 0],
      customSiteFilters: [],
      customEntityFilters: []
    });
  }

  public filterProducts(products: Product[]) {
    let filtered = [...products];
    if (this.filters.currentUsageRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMetricsSupply).currentUsage >=
          this.filters.currentUsageRange[0]
      );
    }

    if (this.filters.currentUsageRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMetricsSupply).currentUsage <=
          this.filters.currentUsageRange[1]
      );
    }

    if (this.filters.usageTodayRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMetricsSupply).usageToday >=
          this.filters.usageTodayRange[0]
      );
    }

    if (this.filters.usageTodayRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMetricsSupply).usageToday <=
          this.filters.usageTodayRange[1]
      );
    }
    if (this.filters.usageYesterdayRange[0] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMetricsSupply).usageYesterday >=
          this.filters.usageYesterdayRange[0]
      );
    }

    if (this.filters.usageYesterdayRange[1] > 0) {
      filtered = filtered?.filter(
        product =>
          (product.metrics as WaterMetricsSupply).usageYesterday <=
          this.filters.usageYesterdayRange[1]
      );
    }
    filtered = super.filterProducts(filtered);
    return filtered;
  }

  public get filtersSet(): boolean {
    return (
      (this.anyFiltersSet || this.lowerBoundSet || this.upperBoundSet) &&
      this.tankParametersSet
    );
  }

  public get lowerBoundSet(): boolean {
    return [
      this.filters.currentUsageRange[0],
      this.filters.usageTodayRange[0],
      this.filters.usageYesterdayRange[0]
    ].some(value => value != 0);
  }

  public get upperBoundSet(): boolean {
    return [
      this.checkRange(this.filters.currentUsageRange, this.maxCurrentUsage),
      this.checkRange(this.filters.usageTodayRange, this.maxUsageToday),
      this.checkRange(this.filters.usageYesterdayRange, this.maxUsageYesterday)
    ].includes(true);
  }

  public setMaxUsageToday(maxVal: number) {
    if (
      this.filters.usageTodayRange[1] == this.maxUsageToday ||
      this.filters.usageTodayRange[1] == null ||
      this.filters.usageTodayRange[1] == 0
    ) {
      this.filters.usageTodayRange[1] = maxVal;
    }

    this.maxUsageToday = maxVal;
  }

  public setMaxUsageYesterday(maxVal: number) {
    if (
      this.filters.usageYesterdayRange[1] == this.maxUsageYesterday ||
      this.filters.usageYesterdayRange[1] == null ||
      this.filters.usageYesterdayRange[1] == 0
    ) {
      this.filters.usageYesterdayRange[1] = maxVal;
    }

    this.maxUsageYesterday = maxVal;
  }

  public setMaxCurrentUsage(maxVal: number) {
    if (
      this.filters.currentUsageRange[1] == this.maxCurrentUsage ||
      this.filters.currentUsageRange[1] == null ||
      this.filters.currentUsageRange[1] == 0
    ) {
      this.filters.currentUsageRange[1] = maxVal;
    }

    this.maxCurrentUsage = maxVal;
  }

  public setMaxRanges(products: Product[]) {
    this.setMaxUsageToday(Math.ceil(reduceMaxValue(products, 'usageToday')));
    this.setMaxUsageYesterday(
      Math.ceil(reduceMaxValue(products, 'usageYesterday'))
    );
    this.setMaxCurrentUsage(
      Math.ceil(reduceMaxValue(products, 'currentUsage'))
    );
  }

  public get tankParametersSet(): boolean {
    return (
      this.maxUsageToday != 0 &&
      this.maxUsageYesterday != 0 &&
      this.maxCurrentUsage != 0
    );
  }
}
