import ProductAPI from '@/services/api/models/ProductAPI';
import Comment from '@/store/models/Comment';
import { Product, WaterMetricsDefault } from '@/store/models/Product';
import { formatComment, formatWaterAlert } from '../TableFormatters';
import {
  formatEntitiesAPI,
  formatGroupedEntity,
  getCombinedEntityCustomFields,
  getMaxValue
} from './EnititesFormatter';

/**
 * Formats water entity list from API: JSON parsing, formatting fields and handling UAT data
 */
export function formatWaterEntitiesAPI(entities: ProductAPI[]) {
  const formattedEntities = entities.map((entity: ProductAPI) => {
    let metrics;

    if (!entity.metrics) {
      metrics = WaterMetricsDefault;
    } else {
      metrics =
        typeof entity.metrics === 'string'
          ? JSON.parse(entity.metrics)
          : entity.metrics;
    }

    return {
      ...formatEntitiesAPI(entity),
      entityComments: formatComment(entity, null),
      metrics: entity
        ? {
            currentUsage: metrics.currentUsage,
            usageToday: metrics.volumeToday,
            usageYesterday: metrics.volumeYesterday,
            alert: formatWaterAlert(metrics.lastReading?.flow)
          }
        : WaterMetricsDefault
    } as Product;
  });
  return formattedEntities;
}

/**
 * Groups list of water entities by product id to create a list of products with entities
 * Returns map
 *
 */
export function getGroupedWaterProducts(entities: Product[]) {
  const map = new Map();
  entities.forEach((item: Product) => {
    const collection = map.get(item.productId);
    if (!collection) {
      map.set(item.productId, {
        ...item,

        maxCurrentUsage: getMaxValue(item, 0, null, 'currentUsage'),
        entities: [
          {
            ...formatGroupedEntity(item)
          }
        ]
      });
    } else {
      const index = collection.entities.length;
      collection.entities.push({
        ...formatGroupedEntity(item)
      });
      collection.maxCurrentUsage = getMaxValue(
        item,
        index,
        collection.maxCurrentUsage,
        'currentUsage'
      );
      collection.entityCustomFieldData = getCombinedEntityCustomFields(
        collection.entityCustomFieldData,
        item.entityCustomFieldData
      );
    }
  });
  return map;
}

export function formatWaterProducts(entities: Product[]): Product[] {
  return Array.from(getGroupedWaterProducts(entities)).map(
    product => product[1]
  );
}
