import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';

import store from '../index';

import modulesNames from '../moduleNames';

import {
  EditCustomFieldDefinition,
  GetCustomFieldDefinitions
} from '@/services/api/CustomFieldApi';
import {
  GetComplianceMethods,
  GetOrgCustomSettings,
  UpdateCustomSetting
} from '@/services/api/CustomSettingApi';
import CustomFieldRequest from '@/services/api/models/requests/CustomFieldRequest';
import { getAppConstant, getEntityApiWord, isApp } from '@/utils/AppName';
import { Constants } from '@/utils/Constants';
import {
  GetVisibleEntityCustomColumns,
  GetVisibleSiteCustomColumns
} from '@/utils/CustomColumns';
import CustomField, { CustomFieldDefinitions } from '../models/CustomField';
import {
  CustomSettings,
  FuelCustomSettingsData,
  MilkCustomSettingsData
} from '../models/CustomSettingsData';
import CustomState from '../models/CustomState';
import {
  ComplianceSettings,
  GroupedComplianceSettings
} from '../models/MilkScore';
import TableColumn from '../models/TableColumn';
import User from './User';
import CustomSettingsRequest from '@/services/api/models/requests/CustomSettingsRequest';
import groupBy, { generateMap } from '@/utils/GroupBy';

// Refer to https://github.com/championswimmer/vuex-module-decorators
// We can probably simplify this store a lot.
@Module({ dynamic: true, namespaced: true, store, name: modulesNames.custom })
class CustomStore extends VuexModule implements CustomState {
  private _customFieldDefinitions?: CustomFieldDefinitions = undefined;
  private _customColumns?: TableColumn[] = [];
  private _customEntityColumns?: TableColumn[] = [];
  private _orgCustomSettings?: CustomSettings = undefined;
  private _groupedComplianceMethods?: GroupedComplianceSettings = undefined;

  // Holds object of the definitions for all entity types
  get customFieldDefinitions(): CustomFieldDefinitions | undefined {
    return this._customFieldDefinitions;
  }

  // Site level custom fields formatted with column values
  get customColumns(): TableColumn[] | undefined {
    return this._customColumns;
  }

  // Entity level custom fields formatted as column values
  get customEntityColumns(): TableColumn[] | undefined {
    return this._customEntityColumns;
  }

  // Only the visible site level custom columns
  get customVisibleColumns(): TableColumn[] | undefined {
    return this._customColumns?.filter(custom => custom.visible);
  }

  // Only the visible entity level custom columns
  get customVisibleEntityColumns(): TableColumn[] | undefined {
    return this._customEntityColumns?.filter(custom => custom.visible);
  }

  // Site level custom field definitions
  get customFields(): CustomField[] | undefined {
    return this._customFieldDefinitions?.products ?? [];
  }

  // Entity level custom field definitions
  get customEntityFields(): CustomField[] | undefined {
    return this._customFieldDefinitions
      ? this._customFieldDefinitions[getEntityApiWord()] ?? []
      : [];
  }

  get orgCustomSettings(): CustomSettings | undefined {
    return this._orgCustomSettings;
  }

  get customEntitySettings():
    | FuelCustomSettingsData
    | MilkCustomSettingsData
    | undefined {
    switch (getAppConstant()) {
      case Constants.PRODUCT_TYPE_FUEL:
        return this._orgCustomSettings?.fuel?.tanks;
      case Constants.PRODUCT_TYPE_MILK:
        return this._orgCustomSettings?.milk?.vats;
      case Constants.PRODUCT_TYPE_FEED:
        return this._orgCustomSettings?.feed?.feed_tanks;
      default:
        return undefined;
    }
  }

  get complianceMethods(): ComplianceSettings[] {
    return this._orgCustomSettings?.milk?.vats?.complianceMethods ?? [];
  }

  get groupedComplianceMethods(): GroupedComplianceSettings | undefined {
    return this._groupedComplianceMethods;
  }

  @Mutation
  private UPDATE_CUSTOM_FIELDS_DEFINITIONS(
    customFieldDefinitions: CustomFieldDefinitions
  ): void {
    this._customFieldDefinitions = customFieldDefinitions;
  }

  @Mutation
  private UPDATE_CUSTOM_COLUMNS(customColumns: TableColumn[]): void {
    this._customColumns = customColumns;
  }

  @Mutation
  private UPDATE_CUSTOM_ENTITY_COLUMNS(
    customEntityColumns: TableColumn[]
  ): void {
    this._customEntityColumns = customEntityColumns;
  }

  @Mutation
  private UPDATE_CUSTOM_SETTINGS(customSettings: CustomSettings): void {
    this._orgCustomSettings = customSettings;
  }

  @Mutation
  private UPDATE_GROUPED_COMPLIANCE_METHODS(
    groupedMethods: GroupedComplianceSettings
  ): void {
    this._groupedComplianceMethods = groupedMethods;
  }

  @Action({ rawError: true })
  public updateCustomFieldDefinitionsState(
    customFieldDefinitions: CustomFieldDefinitions
  ): void {
    this.UPDATE_CUSTOM_FIELDS_DEFINITIONS(customFieldDefinitions);
  }

  @Action({ rawError: true })
  public updateCustomColumnsState(customColumns: TableColumn[]): void {
    this.UPDATE_CUSTOM_COLUMNS(customColumns);
  }

  @Action({ rawError: true })
  public updateCustomEntityColumnsState(
    customEntityColumns: TableColumn[]
  ): void {
    this.UPDATE_CUSTOM_ENTITY_COLUMNS(customEntityColumns);
  }

  @Action({ rawError: true })
  public updateCustomSettings(customSettings: CustomSettings): void {
    this.UPDATE_CUSTOM_SETTINGS(customSettings);
  }

  @Action({ rawError: true })
  public async editCustomSettings(request: CustomSettingsRequest) {
    const customSettings = await UpdateCustomSetting(request);
    this.UPDATE_CUSTOM_SETTINGS(customSettings);
  }

  @Action({ rawError: true })
  public async fetchCustomFieldDefinitions(): Promise<CustomFieldDefinitions> {
    const fields = await GetCustomFieldDefinitions(User.orgId ?? 0);
    this.updateCustomFieldDefinitionsState(fields);
    return fields;
  }
  @Action({ rawError: true })
  public async editCustomFieldDefinitions(
    request: CustomFieldRequest
  ): Promise<CustomFieldDefinitions> {
    const fields = await EditCustomFieldDefinition(request);
    this.updateCustomFieldDefinitionsState(fields);
    return fields;
  }

  @Action({ rawError: true })
  public async fetchVisibleCustomColumns(): Promise<TableColumn[]> {
    const columns = await GetVisibleSiteCustomColumns();
    this.updateCustomColumnsState(columns);
    return columns;
  }

  @Action({ rawError: true })
  public async fetchVisibleCustomEntityColumns(): Promise<TableColumn[]> {
    const columns = await GetVisibleEntityCustomColumns();
    this.updateCustomEntityColumnsState(columns);
    return columns;
  }

  @Action({ rawError: true })
  public async fetchCustomSettings(): Promise<CustomSettings> {
    const settings = await GetOrgCustomSettings(User.orgId ?? 0, false);
    this.updateCustomSettings(settings);

    return settings;
  }

  @Action({ rawError: true })
  public async fetchComplianceMethods() {
    const complianceMethods = await GetComplianceMethods(User.orgId ?? 0);
    const groupedComplianceMethods = generateMap(
      complianceMethods,
      (value: ComplianceSettings) => value.complianceMethod
    );
    this.UPDATE_GROUPED_COMPLIANCE_METHODS(groupedComplianceMethods);
  }

  @Action({ rawError: true })
  public async setAllCustomStates() {
    await this.fetchCustomFieldDefinitions();
    await this.fetchVisibleCustomColumns();
    await this.fetchVisibleCustomEntityColumns();
    await this.fetchCustomSettings();
    if (isApp(Constants.PRODUCT_TYPE_MILK)) {
      await this.fetchComplianceMethods();
    }
  }
}

export default getModule(CustomStore);
